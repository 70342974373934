<template>
  <v-main align="center">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="10">
          <v-card class="elevation-12">
            <v-row justify="center" align="center">
              <v-col cols="12" class="pt-0 mt-0">
                <v-img
                  max-width="100%"
                  v-bind:src="require('../assets/contactmap.jpg')"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-card class="elevation-0">
                  <h2 class="headline mt-2">
                    {{ $t("contact.descriptionTitle") }}
                  </h2>
                  <v-list three-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("contact.locationSiencepark")
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("contact.locationStreet")
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("contact.locationPostalcode")
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("contact.locationCountry")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list>
                </v-card>
              </v-col>

              <v-col cols="12" sm="12" md="5">
                <v-card class="elevation-0">
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="headline">{{
                          $t("contact.emailTitle")
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          $t("contact.email")
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="headline">{{
                          $t("contact.phoneTitle")
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          $t("contact.phoneOne")
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          $t("contact.phoneTwo")
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Alarm",
  computed: {},
  methods: {},
  created() {},
};
</script>

<style>
</style>